<template>
  <HgLogitravelPackageTourSearch
    :is-mobile="isMobile"
    :airports="allAirports"
    :searched-airports="searchAirports"
    :default-date-relative="5"
    :search-url="url ? url : null"
    :destinations="autosuggestAmadeus"
    :is-button-disabled="isButtonDisabled"
    @onChangeAirportTerm="changeAirportTerm"
    @onUpdateDestinationTerm="updateDestinationTerm"
    @onUpdateSearchParameters="updateSearchParameters"
    @onTriggerSearch="triggerSearch"
  />
</template>

<script>
import { mapState } from 'vuex';
import logiTravelDestination from '@/apollo/queries/logitravelSearch/logitravelDestination.gql';
import LogiTravelPackageTourSearchQuery from '@/apollo/queries/logitravelSearch/logitravelPackageTourSearch.gql';
import allAirportsQuery from '@/apollo/queries/airport/allAirports';
import GtmEventsMixin from '@/mixins/GtmEventsMixin';
import { addDays, format } from 'date-fns';

export default {
  mixins: [GtmEventsMixin],
  data: () => ({
    destinationTerm: '',
    allAirports: [],
    searchAirports: [],
    url: '',
    suggestedDestinations: [],
    autosuggestAmadeus: [],
    isButtonDisabled: true
  }),

  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile
    })
  },

  mounted() {
    this.queryDefaultAirports();
    this.queryAirports('').then((res) => {
      this.searchAirports = [...res];
    });
    this.getUrl();
  },

  methods: {
    updateSearchParameters(value) {
      this.getUrl(value);
    },

    updateDestinationTerm(term) {
      this.destinationTerm = term;
      this.isLoading = true;

      this.debounce(this.queryDestinations, 300);
    },

    triggerSearch(event) {
      this.$gtm.push({
        event: 'IBE_search_used',
        destination: event.destination.suggestion,
        period: `${format(event.startDate.start, 'EE, dd.MM.yyyy')} - ${format(
          event.startDate.end,
          'EE, dd.MM.yyyy'
        )}`,
        travellers: event.travelers,
        departureAirport: event.airports
      });
      this.getUrl(event);
    },

    async queryDestinations() {
      if (this.destinationTerm === '') {
        this.isLoading = false;
        return;
      }
      try {
        const { data } = await this.$apollo.query({
          query: logiTravelDestination,
          variables: {
            query: this.destinationTerm
          }
        });
        this.autosuggestAmadeus = data.logitravelDestinationAutosuggest;
      } catch (e) {
        // eslint-disable-next-line
        console.warn('queryDestinations error', e);
      } finally {
        this.isLoading = false;
      }
    },

    async getUrl(input) {
      if (
        input &&
        input.startDate &&
        input.destination &&
        input.airports.length > 0 &&
        input.travelers
      ) {
        this.isButtonDisabled = false;
        try {
          const { data } = await this.$apollo.query({
            query: LogiTravelPackageTourSearchQuery,
            variables: {
              input: {
                destination: {
                  id: input.destination.id,
                  type: input.destination.type,
                  value: input.destination.value,
                  suggestion: input.destination.suggestion
                },
                startDate: format(input.startDate.start, 'yyyy-MM-dd'),
                airport: input.airports[0],
                numberOfAdults: input.travelers.adults,
                endDate: format(input.startDate.end, 'yyyy-MM-dd'),
                agesOfChildren:
                  input.travelers.children > 0 ? input.travelers.ages : []
              }
            }
          });
          this.url = data.logitravelPackageTourSearchUrl;
        } catch (e) {
          // eslint-disable-next-line
          console.warn('URL Query Error', e);
        }
      } else {
        this.isButtonDisabled = true;
      }
    },

    changeAirportTerm(searchTerm) {
      this.queryAirports(searchTerm).then((res) => {
        this.searchAirports = [...res];
      });
    },

    async queryDefaultAirports() {
      try {
        const {
          data: { allAirports }
        } = await this.$apollo.query({
          query: allAirportsQuery,
          variables: {
            filter: {
              onlyGrouped: true
            }
          }
        });

        this.allAirports = allAirports;
      } catch (e) {
        // eslint-disable-next-line
        console.warn('error', e.graphQLErrors);
      }
    },

    async queryAirports(searchTerm) {
      try {
        const { data } = await await this.$apollo.query({
          query: allAirportsQuery,
          variables: {
            limit: 500,
            filter: {
              name: searchTerm
            }
          }
        });
        return data.allAirports;
      } catch (e) {
        // eslint-disable-next-line
        console.warn('error', e.graphQLErrors);
      }
    },

    debounce(func, delay) {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(func, delay);
    }
  }
};
</script>
